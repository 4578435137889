import axios from 'axios'
import store from '@/store'
import apiUtil from './apiUtil'

export default {
  getDownloadLink (queryParams, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?fm=y&download_file=y&issn=:issn&id_folder=:id_folder&content_type=:content_type&file_name=:file_name&file_status=:file_status')
    
    apiUrl = apiUrl.replace(':issn', queryParams.issn)
    apiUrl = apiUrl.replace(':id_folder', queryParams.id_folder)
    apiUrl = apiUrl.replace(':content_type', queryParams.content_type)
    apiUrl = apiUrl.replace(':file_name', queryParams.file_name)
    apiUrl = apiUrl.replace(':file_status', queryParams.file_status)
    apiUrl = apiUrl.concat('&volume=', queryParams.volume, '&issue=', queryParams.issue)
    apiUrl = apiUrl.concat('&athenable_tk=', token)

    console.log('calling ttttt:', apiUrl)
  
    /**/
    axios
      .get(apiUrl)
      .then(response => {
        const returnData = {
          url: response.data,
          name: queryParams.file_name
        }
        console.log('zz backFromGetDownloadLink', returnData)
        callbackComponet.backFromGetDownloadLink(returnData)
      })
      .catch(error => {
        callbackComponet.backFromGetDownloadLink(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  getDownloadArticleLink (queryParams, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?fm=y&download_article=y&issn=:issn&types=:types&xml_file_name=:xml_file_name&file_status=:file_status')
    
    apiUrl = apiUrl.replace(':issn', queryParams.issn)
    apiUrl = apiUrl.replace(':types', queryParams.types)
    apiUrl = apiUrl.replace(':xml_file_name', queryParams.xml_file_name)
    apiUrl = apiUrl.replace(':file_status', queryParams.file_status)
    apiUrl = apiUrl.concat('&volume=', queryParams.volume, '&issue=', queryParams.issue)
    apiUrl = apiUrl.concat('&athenable_tk=', token)

    console.log('calling ttttt:', apiUrl)
    /**/
    axios
      .get(apiUrl)
      .then(response => {
        callbackComponet.backFromGetDownloadArticleLink(response.data)
      })
      .catch(error => {
        callbackComponet.backFromGetDownloadArticleLink(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  getDownloadWordFromXML (queryParams, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?fm=y&download_word_from_xml=y&issn=:issn&xml_file_name=:xml_file_name&file_status=:file_status')
    
    apiUrl = apiUrl.replace(':issn', queryParams.issn)
    apiUrl = apiUrl.replace(':xml_file_name', queryParams.xml_file_name)
    apiUrl = apiUrl.replace(':file_status', queryParams.file_status)
    apiUrl = apiUrl.concat('&volume=', queryParams.volume, '&issue=', queryParams.issue)
    apiUrl = apiUrl.concat('&athenable_tk=', token)

    console.log('calling ttttt:getDownloadWordFromXML ', apiUrl)
    /**/
    axios
      .get(apiUrl)
      .then(response => {
        callbackComponet.backFromGetDownloadWordFromXML(response.data)
      })
      .catch(error => {
        callbackComponet.backFromGetDownloadWordFromXML(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  getTaxonomyExportLink (queryParams, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?fm=y&taxonomy_export=y&tax_uri_name=:tax_uri_name&issn=:issn')
    
    apiUrl = apiUrl.replace(':issn', queryParams.issn)
    apiUrl = apiUrl.replace(':tax_uri_name', queryParams.tax_uri_name)
    apiUrl = apiUrl.concat('&athenable_tk=', token)

    console.log('calling ttttt:', apiUrl)
    /**/
    axios
      .get(apiUrl)
      .then(response => {
        callbackComponet.backFromGetTaxonomyExportLink(response.data)
      })
      .catch(error => {
        callbackComponet.backFromGetTaxonomyExportLink(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  getCrossrefDepositDownloadLink (queryParams, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?fm=y&crossref_deposit_download_link=y&doi=:doi&issn=:issn&build_new=:build_new')
    
    apiUrl = apiUrl.replace(':issn', queryParams.issn)
    apiUrl = apiUrl.replace(':doi', queryParams.doi)
    apiUrl = apiUrl.replace(':build_new', queryParams.buildNew)
    if (queryParams.vol) {
      apiUrl = apiUrl.concat('&vol=', queryParams.vol, '&iss=', queryParams.iss)
    }
    apiUrl = apiUrl.concat('&athenable_tk=', token)

    console.log('calling ttttt:', apiUrl)
    /**/
    axios
      .get(apiUrl) 
      .then(response => {
        callbackComponet.backFromGetCrossrefDepositDownloadLink(response.data)
      })
      .catch(error => {
        callbackComponet.backFromGetCrossrefDepositDownloadLink(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  getJobDownloadLink (issn, job, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?fm=y&job_download_link=y&issn=:issn&jobType=:jobType&jobTimestamp=:jobTimestamp&jobFilename=:jobFilename')
    
    apiUrl = apiUrl.replace(':issn', issn)
    apiUrl = apiUrl.replace(':jobType', job.jobType)
    apiUrl = apiUrl.replace(':jobTimestamp', job.jobTimestamp)
    apiUrl = apiUrl.replace(':jobFilename', job.fileForDownload)
    apiUrl = apiUrl.concat('&athenable_tk=', token)

    console.log('calling ttttt:', apiUrl)
    /**/
    axios
      .get(apiUrl)
      .then(response => {
        callbackComponet.backFromGetJobDownloadLink(response.data)
      })
      .catch(error => {
        callbackComponet.backFromGetJobDownloadLink(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  }, 
  getWebImageUrls (queryParams, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore

    let apiUrl = ''
    if (window.location.href.includes('submission.versionofrecord.com')) {
      apiUrl = apiUriPrefix.concat('submissions?fm=y&doi=:doi&issn=:issn&file_status=:file_status&size=:size')
    } else {
      apiUrl = apiUriPrefix.concat('api?fm=y&doi=:doi&issn=:issn&file_status=:file_status&size=:size')
    }
    
    apiUrl = apiUrl.replace(':issn', queryParams.issn)
    apiUrl = apiUrl.replace(':doi', queryParams.doi)
    apiUrl = apiUrl.replace(':file_status', queryParams.file_status)
    apiUrl = apiUrl.replace(':size', queryParams.size)
    
    if (queryParams.volume) {
      apiUrl = apiUrl.concat('&volume=', queryParams.volume, '&issue=', queryParams.issue)
    }
    if (queryParams.folder) {
      apiUrl = apiUrl.concat('&folder=', queryParams.folder)
    }
    
    if (window.location.href.includes('submission.versionofrecord.com')) {
      apiUrl = apiUrl.concat('&subms_tk=', '123')
    } else {
      apiUrl = apiUrl.concat('&athenable_tk=', token)
    }

    const requestObj = {
      action: 'web_image_urls',
      image_names: queryParams.image_names,
    }

    console.log('calling ttttt getWebImageUrls:', apiUrl, requestObj)
    /**/
    axios
      .post(apiUrl, requestObj) 
      .then(response => {
        callbackComponet.backFromGetWebImageUrls(response.data)
      })
      .catch(error => {
        callbackComponet.backFromGetWebImageUrls(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  uploadManuscriptRequest (queryParams, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    //const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('submissions?fm=y&initializeManuscriptForUpload=y&issn=:issn&file_name=:file_name')
 
    apiUrl = apiUrl.replace(':issn', queryParams.issn)
    apiUrl = apiUrl.replace(':file_name', queryParams.rawFile.name)
    apiUrl = apiUrl.concat('&subms_tk=', '123')

    console.log(' initializeManuscriptForUpload calling apiUrl', apiUrl)
    const self = this
    axios
      .get(apiUrl)
      .then(response => {
        //console.log('resonse 1111', response.data, callbackComponet)
        self.uploadManuscript(queryParams.rawFile, response.data, callbackComponet, loadingContainer)
      })
      .catch(error => {
        console.log('uploadFileRequest error', error, callbackComponet)
        callbackComponet.backFromUploadManuscriptRequest(apiUtil.handleError(error))
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  uploadManuscript (rawFile, s3Data, callbackComponet, loadingContainer) {
    console.log('Upload File', s3Data)
    const postData = new FormData()
    const keyVals = Object.entries(s3Data.fields)
    for (let i = 0; i <  keyVals.length; i++) {
      const [key, value] = keyVals[i]
      postData.append(key, value)
    }
    postData.append('file', rawFile)
    axios 
      .post(s3Data.url, postData)
      .then(result => { 
        console.log('uploadManuscript backFromUploadManuscriptRequest result', result)
        callbackComponet.backFromUploadManuscriptRequest(result)
      })
      .catch(error => { 
        console.log('uploadManuscript backFromUploadManuscriptRequest error', error)
        callbackComponet.backFromUploadManuscriptRequest(apiUtil.handleError(error))
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  uploadFileRequest (queryParams, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('api?fm=y&initializeFileForUpload=y&issn=:issn&id_folder=:id_folder&content_type=:content_type&file_name=:file_name&file_status=:file_status')
 
    apiUrl = apiUrl.replace(':issn', queryParams.issn)
    apiUrl = apiUrl.replace(':content_type', queryParams.content_type)
    apiUrl = apiUrl.replace(':file_name', queryParams.rawFile.name)
    apiUrl = apiUrl.replace(':file_status', queryParams.file_status)
    apiUrl = apiUrl.replace(':id_folder', queryParams.id_folder)

    if (queryParams.file_status === 'production') {
      apiUrl = apiUrl.concat('&volume=', queryParams.volume, '&issue=', queryParams.issue)
    }
    apiUrl = apiUrl.concat('&athenable_tk=', token)

    console.log(' uploadFileRequest calling apiUrl', apiUrl)
    const self = this
    axios
      .get(apiUrl)
      .then(response => {
        //console.log('resonse 1111', response.data, callbackComponet)
        self.uploadFile(queryParams.rawFile, response.data, callbackComponet, loadingContainer)
      })
      .catch(error => {
        console.log('uploadFileRequest error', error, callbackComponet)
        callbackComponet.backFromUploadFileRequest(apiUtil.handleError(error))
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  uploadFile (rawFile, s3Data, callbackComponet, loadingContainer) {
    console.log('Upload File', s3Data)
    const postData = new FormData()
    const keyVals = Object.entries(s3Data.fields)
    for (let i = 0; i <  keyVals.length; i++) {
      const [key, value] = keyVals[i]
      postData.append(key, value)
    }
    postData.append('file', rawFile)
    axios 
      .post(s3Data.url, postData)
      .then(result => { 
        console.log('uploadFile backFromUploadFileRequest result', result)
        callbackComponet.backFromUploadFileRequest(result)
      })
      .catch(error => { 
        console.log('uploadFile backFromUploadFileRequest error', error)
        callbackComponet.backFromUploadFileRequest(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  /*
  uploadImageFile (raw_file, s3Data, callbackComponet, loadingContainer) {
    console.log('Upload File')
    const postData = new FormData()
    for (const [key, value] of Object.entries(s3Data.fields)) {
      postData.append(key, value)
    }
    postData.append('file', raw_file)
    axios
      .post(s3Data.url, postData)
      .then((result) => {  
        callbackComponet.backFromUserUploadImageRequest(result)
      })
      .catch((error) => { 
        callbackComponet.backFromUserUploadImageRequest(apiUtil.handleError(error))
      })
      .finally(function () {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  uploadAudioFile (raw_file, s3Data, callbackComponet, loadingContainer) {
    console.log('Upload File')
    const postData = new FormData()
    for (const [key, value] of Object.entries(s3Data.fields)) {
      postData.append(key, value)
    }
    postData.append('file', raw_file)
    axios
      .post(s3Data.url, postData)
      .then((result) => { 
        callbackComponet.backFromUserUploadAudioRequest(result)
      })
      .catch((error) => { 
        callbackComponet.backFromUserUploadAudioRequest(apiUtil.handleError(error))
      })
      .finally(function () {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  uploadVideoFile (raw_file, s3Data, callbackComponet, loadingContainer) {
    console.log('Upload File')
    const postData = new FormData()
    for (const [key, value] of Object.entries(s3Data.fields)) {
      postData.append(key, value)
    }
    postData.append('file', raw_file)
    axios
      .post(s3Data.url, postData)
      .then((result) => { 
        console.log('upload complete', result)
        callbackComponet.backFromUserUploadVideoRequest(result)
      })
      .catch((error) => { 
        console.log('upload error', error)
      })
      .finally(function () {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  userUploadImageRequest (raw_file, file_path, c_id, callbackComponet, loadingContainer) {
    console.log('userUploadImageRequest', file_path)
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const token = store.state.AppActiveUser.token
    const apiUriPrefix = store.state.athenableStore.apiUriPrefix
    const api_url = apiUriPrefix.concat('api?initializeFileForUpload=y&file_path=', file_path, '&course_id=', c_id, '&athenable_tk=', token)
  
    //console.log('calling ttttt:', api_url)
    
    const self = this
    axios
      .get(api_url)
      .then(function (response) {
        //console.log('resonse 1111', response.data)
        self.uploadImageFile(raw_file, response.data, callbackComponet, loadingContainer)
        
      })
      .catch(function (error) {
        callbackComponet.backFromUserUploadImageRequest(apiUtil.handleError(error))
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  userUploadAudioRequest (raw_file, file_path, c_id, callbackComponet, loadingContainer) {
    console.log('userUploadAudioRequest', file_path)
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const token = store.state.AppActiveUser.token
    const apiUriPrefix = store.state.athenableStore.apiUriPrefix
    const api_url = apiUriPrefix.concat('api?initializeFileForUpload=y&file_path=', file_path, '&course_id=', c_id, '&athenable_tk=', token)
  
    //console.log('calling ttttt:', api_url)
    
    const self = this
    axios
      .get(api_url)
      .then(function (response) {
        //console.log('resonse 1111', response.data)
        self.uploadAudioFile(raw_file, response.data, callbackComponet, loadingContainer)
        
      })
      .catch(function (error) {
        callbackComponet.backFromUserUploadAudioRequest(apiUtil.handleError(error))
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  userUploadVideoRequest (raw_file, file_path, c_id, callbackComponet, loadingContainer) {
    console.log('userUploadVideoRequest', file_path, c_id)
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const token = store.state.AppActiveUser.token
    const apiUriPrefix = store.state.athenableStore.apiUriPrefix
    const api_url = apiUriPrefix.concat('api?initializeFileForUpload=y&file_path=', file_path, '&course_id=', c_id, '&athenable_tk=', token)
  
    //console.log('calling ttttt:', api_url)
    const self = this
    axios
      .get(api_url)
      .then(function (response) {
        //console.log('resonse 1111', response.data)
        self.uploadVideoFile(raw_file, response.data, callbackComponet, loadingContainer)

      })
      .catch(function (error) {
        callbackComponet.backFromUserUploadVideoRequest(apiUtil.handleError(error))
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },

  getCourseImages (c_id, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const token = store.state.AppActiveUser.token
    const apiUriPrefix = store.state.athenableStore.apiUriPrefix
    const api_url = apiUriPrefix.concat('api?courseImages=y&course_id=', c_id, '&athenable_tk=', token)
  
    //console.log('calling ttttt:', api_url)
    
    axios
      .get(api_url)
      .then(function (response) {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromGetCourseImages(response.data)
      })
      .catch(function (error) {
        callbackComponet.backFromGetCourseImages(apiUtil.handleError(error))
      })
      .finally(function () {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  getCourseAudio (c_id, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const token = store.state.AppActiveUser.token
    const apiUriPrefix = store.state.athenableStore.apiUriPrefix
    const api_url = apiUriPrefix.concat('api?courseAudio=y&course_id=', c_id, '&athenable_tk=', token)
  
    //console.log('calling ttttt:', api_url)
    
    axios
      .get(api_url)
      .then(function (response) {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromGetCourseAudio(response.data)
      })
      .catch(function (error) {
        callbackComponet.backFromGetCourseAudio(apiUtil.handleError(error))
      })
      .finally(function () {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
  getCourseVideos (c_id, callbackComponet, loadingContainer) {
    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    const token = store.state.AppActiveUser.token
    const apiUriPrefix = store.state.athenableStore.apiUriPrefix
    const api_url = apiUriPrefix.concat('api?courseVideos=y&course_id=', c_id, '&athenable_tk=', token)
  
    //console.log('calling ttttt:', api_url)
    
    axios
      .get(api_url)
      .then(function (response) {
        //console.log('resonse 1111', response.data)
        callbackComponet.backFromGetCourseVideos(response.data)
      })
      .catch(function (error) {
        callbackComponet.backFromGetCourseVideos(apiUtil.handleError(error))
      })
      .finally(function () {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  }
  */
}
